const projectsData = [
  {
    id: 1,
    name: "Museum of Imagination",
    image: require("../assets/images/projects/MuseumOfImagination-project.png"),
    description: "A virtual museum experience using modern web technologies.",
    tags: ["HTML", "CSS", "JavaScript", "Three.js"],
    link: "https://wonderland-3c94c.firebaseapp.com/index.html"
  },
  {
    id: 2,
    name: "Breakfast Bites & Bytes",
    image: require("../assets/images/projects/BreakfastHacksprint-project.png"),
    description: "A website for healthy breakfast recipes powered by APIs.",
    tags: ["HTML", "CSS", "Bootstrap"],
    link: "https://ckcarr.github.io/breakfast_hacksprint/"
  },
  {
    id: 3,
    name: "Printf - C Language",
    image: require("../assets/images/ck-logo.png"),
    description: "Recreated the 'printf' function in C, with formatting capabilities.",
    tags: ["C", "Standard I/O"],
    link: "https://github.com/CKCarr/holbertonschool-printf"
  },
  {
    id: 4,
    name: "Simple Shell - UNIX Command Interpreter",
    image: require("../assets/images/ck-logo.png"),
    description: "Developed a simple UNIX command interpreter in C.",
    tags: ["C", "Shell", "CLI"],
    link: "https://github.com/CKCarr/holbertonschool-simple_shell"
  },
  {
    id: 5,
    name: "Mock AirBnB Clone",
    image: require("../assets/images/ck-logo.png"),
    description: "Built a clone of AirBnB using Python, HTML, CSS, and MySQL.",
    tags: ["Python", "Flask", "MySQL", "HTML", "CSS"],
    link: "https://github.com/CKCarr/holbertonschool-AirBnB_clone_v4"
  },
  {
    id: 6,
    name: "A Way Home - Pet Lost & Found App",
    image: require("../assets/images/ck-logo.png"),
    description: "Capstone project for a lost and found pet application using React and Next.js.",
    tags: ["Next.js", "React", "Tailwind CSS", "API", "Express"],
    link: "https://www.youtube.com/watch?v=z-WmWKJJnDc"
  },
  {
    id: 7,
    name: "Cinema Guru - React Movie App",
    image: require("../assets/images/ck-logo.png"),
    description: "Created a React front-end app connecting to a movie API with login and 'Watch Later' features.",
    tags: ["React", "JavaScript", "Movie API", "Login"],
    link: "https://github.com/CKCarr/atlas-cinema-guru"
  },
  {
    id: 8,
    name: "Headphones",
    image: require("../assets/images/projects/headphones.png"),
    description: "Javascript HTML and CSS project",
    tags: ["HTML", "JavaScript", "CSS"],
    link: "https://ckcarr.github.io/holbertonschool-headphones/4-index"
  },
  {
    id: 9,
    name: "MERN Employee Manager",
    image: require("../assets/images/projects/EmployeeManager.png"),
    description: "MERN stack application for managing employees.",
    tags: ["MongoDB", "JavaScript", "CSS", "React", "Express", "Node.js"],
    link: "https://www.youtube.com/watch?v=9igmP44iPto&t=14s"
  }
];

export default projectsData;
